@use '@lichtblick/photon/theme/global' as *;

.form {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: 550px;
  margin-bottom: $spacing-xs;
  gap: $spacing-xs;
}

.section {
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    padding-right: calc(
      max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
    );
    padding-left: calc(
      max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
    );
  }

  &.start {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    min-height: 70dvh;

    .form {
      flex-direction: column;
    }
  }
}

.headline {
  display: block;
  margin-bottom: $spacing-xxs;
}

.result-count {
  position: relative;
  margin-bottom: $spacing-xxs;
  color: $color-smoke;
}

.result-count-placeholder {
  @include placeholder-element;
}

.search-input {
  width: 100%;
}

.calculator {
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;
}

.result-list {
  list-style: none;
}

.result-list-placeholder {
  min-height: 80vh;
}

.result {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $spacing-xxs;
  padding: $spacing-xxs $spacing-xs;
  transition: transform 0.1s;
  border: solid 1px $color-light-gray;
  border-radius: $border-radius-s;
  background: $color-white;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: $spacing-xs;
    padding: $spacing-xs $spacing-s;
  }

  @include focus-ring;

  &:hover {
    background: color-mix(in srgb, currentcolor 11%, $color-white);
  }

  &:active {
    transform: scale(0.98);
  }
}

.date {
  color: $color-smoke;
}

.page-indexes {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: $spacing-s;
  gap: $spacing-xxs;

  li {
    list-style: none;
  }
}

.active-index > span {
  background-size: 100% var(--link-underline-width) !important;
}
